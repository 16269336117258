import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import PropTypes from "prop-types";

var getListCards = (data, classes) => {
  var rightCards = [], leftCards = [], bottomText = [];
  data.allStrapiEntities.edges.map((document, index) => {
    if (document.node.displayType === `list`) {
      if (index % 2 === 1) {
        let rightDescription =
          (<ul>
            {document.node.description.split(`\n\n`).map((value, index) => (<li key={`des-` + index}>{value}</li>))}
          </ul>);
        let rightTitle = document.node.title.split(`:`);
        rightCards.push(
          <Card blog className={classes.card} key={`right-citizen-cards-${index}`}>
            <CardBody plain>
              <h4
                style={{ textAlign: `left`, paddingLeft: `25px` }}
                className={classes.cardTitle}>
                {rightTitle[0]}
              </h4>
              <h4
                style={{ textAlign: `left`, paddingLeft: `25px` }}
                className={classes.cardTitle}>
                {rightTitle[1]}
              </h4>
              <span
                style={{ textAlign: `left` }}
                className={classes.cardDescription}>
                {rightDescription}
              </span>
            </CardBody>
          </Card>
        );

      } else {
        let leftDescription =
          (<ul>
            {document.node.description.split(`\n\n`).map((value, index) => (<li key={`left-` + index}>{value}</li>))}
          </ul>);
        let leftTitle = document.node.title.split(`:`);
        leftCards.push(
          <Card blog className={classes.card} key={`left-citizen-cards-${index}`}>
            <CardBody plain>
              <h4
                style={{ textAlign: `left`, paddingLeft: `25px` }}
                className={classes.cardTitle}>
                {leftTitle[0]}
              </h4>
              <h4
                style={{ textAlign: `left`, paddingLeft: `25px` }}
                className={classes.cardTitle}>
                {leftTitle[1]}
              </h4>
              <span
                style={{ textAlign: `left` }}
                className={classes.cardDescription}>
                {leftDescription}
              </span>
            </CardBody>
          </Card>
        );
      }

    }
    else if (document.node.displayType === `text`) {
      var description2 = document.node.description.split(
        `\n`).map((value, index) => (<div key={`desc-` + index}><span>{value}</span><br /></div>));
      bottomText.push(
        <div>
          {description2}
        </div>
      );
    }
  });

  return {
    rightCards: rightCards,
    leftCards: leftCards,
    bottomText: bottomText
  };

};


const aboutUsCitizen = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query CitizenCharterContent {
        allStrapiEntities(
          filter: { category: { eq: "citizen_charter" } }
          sort: { fields: sequence, order: ASC }
        ) {
          edges {
            node {
              id
              title
              summary
              description
              category
              displayType
            }
          }
        }
      }
    `}
    render={data => (
      <div key={`about-us-citizen`} style={{ textAlign: `center` }}>
        <h2 className={classes.title}>Citizen&apos;s Charter</h2>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12} className={classes.textCenter}>
            {getListCards(data, classes).leftCards}
          </GridItem>
          <GridItem xs={12} md={6} sm={6} className={classes.textCenter}>
            {getListCards(data, classes).rightCards}
          </GridItem>
        </GridContainer>
        <div>
          {getListCards(data, classes).bottomText[0]}
        </div>
      </div>
    )}
  />
);
aboutUsCitizen.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(aboutUsStyle)(aboutUsCitizen);
