import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class aboutUsDirectors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      name: ``,
      designation: ``,
      summary: ``,
      imageSrc: ``
    };
  }
  handleClickOpen(name, designation, summary, imageSrc) {
    // var x = [];
    // x[modal] = true;
    this.setState({
      showModal: true,
      name: name,
      designation: designation,
      summary: summary,
      imageSrc: imageSrc
    });
  }
  handleClose() {
    // var x = [];
    // x[modal] = false;
    this.setState({
      showModal: false
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query BoardDirectorContent {
            allStrapiContacts(filter: { Unit: { eq: "BoardOfDirectors" } }) {
              edges {
                node {
                  id
                  name
                  department
                  designation
                  summary
                  sequence
                  image {
                    childImageSharp {
                      fixed(width: 180, height: 180) {
                        src
                        srcSet
                        base64
                        height
                        width
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let all = data.allStrapiContacts.edges;
          let allData = all.slice();
          let first = allData.splice(0, 1);
          first = first[0];
          return (
            <div>
             <GridContainer className={classes.displayFlex}>
                <GridItem
                  md={12}
                  sm={12}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                >
                  <h2 className={classes.title}>Board Of Directors</h2>
                  <br />
                </GridItem>
                <GridContainer
                  justify="center"
                  style={{ padding: `0`, margin: `0` }}
                >
                  <GridContainer
                    justify="center"
                    style={{ padding: `0`, margin: `0` }}
                  >
                    <GridItem key={`board-dir-chairman`} xs={12} sm={12} md={4}>
                      <div
                        onClick={() =>
                          this.handleClickOpen(
                            first.node.name,
                            first.node.designation,
                            first.node.summary,
                            first.node.image.childImageSharp.fixed.src
                          )
                        }
                      >
                        <VerticalCard
                          title={first.node.name}
                          subtitle={first.node.designation || <br />}
                          description={first.node.summary}
                          classes={classes}
                          alignItems="center"
                          action={
                            <Button
                              simple
                              color="primary"
                              style={{ margin: 0, padding: 0 }}
                              onClick={() =>
                                this.handleClickOpen(
                                  first.node.name,
                                  first.node.designation,
                                  first.node.summary
                                )
                              }
                            >
                              Read More
                            </Button>
                          }
                          image={
                            <>
                              <a
                                href="#pablo"
                                rel="noopener"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  src={
                                    first.node.image.childImageSharp.fixed.src
                                  }
                                  alt="..."
                                />
                              </a>
                              <div
                                className={classes.coloredShadow}
                                style={{
                                  backgroundImage: `url(${first.node.image.childImageSharp.fixed.src})`,
                                  opacity: `1`
                                }}
                              />
                            </>
                          }
                          noShadow
                          onHoverEffect
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  {allData
                    .filter(v => v.node.image !== null)
                    .sort((a, b) => {
                      return a.node.sequence - b.node.sequence;
                    })
                    .map((document, index) => {
                      return (
                        <GridItem
                          key={`board-dir-` + index}
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <div
                            onClick={() =>
                              this.handleClickOpen(
                                document.node.name,
                                document.node.designation,
                                document.node.summary,
                                document.node.image.childImageSharp.fixed.src
                              )
                            }
                          >
                            <VerticalCard
                              title={document.node.name}
                              subtitle={document.node.designation || <br />}
                              description={document.node.summary}
                              classes={classes}
                              alignItems="center"
                              action={
                                <Button
                                  simple
                                  color="primary"
                                  style={{ margin: 0, padding: 0 }}
                                  onClick={() =>
                                    this.handleClickOpen(
                                      document.node.name,
                                      document.node.designation,
                                      document.node.summary
                                    )
                                  }
                                >
                                  Read More
                                </Button>
                              }
                              image={
                                <>
                                  <a
                                    href="#pablo"
                                    rel="noopener"
                                    onClick={e => e.preventDefault()}
                                  >
                                    <img
                                      src={
                                        document.node.image.childImageSharp
                                          .fixed.src
                                      }
                                      alt="..."
                                    />
                                  </a>
                                  <div
                                    className={classes.coloredShadow}
                                    style={{
                                      backgroundImage: `url(${document.node.image.childImageSharp.fixed.src})`,
                                      opacity: `1`
                                    }}
                                  />
                                </>
                              }
                              noShadow
                              onHoverEffect
                            />
                          </div>
                        </GridItem>
                      );
                    })}
                </GridContainer>
              </GridContainer>
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal
                }}
                open={this.state.showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose()}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose()}
                    style={{
                      color: `#000`,
                      float: `right`
                    }}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  <div style={{ textAlign: `center` }}>
                    <img src={this.state.imageSrc} />
                    <h3>{this.state.name}</h3>
                    <h4>{this.state.designation || <br />}</h4>
                    {this.state.summary.split(`\n`).map((data, key) => {
                      return (
                        <div
                          key={key}
                          className={classes.contentStyles}
                        >
                          <Markdown>{data}</Markdown>
                        </div>
                      );
                    })}
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          );
        }}
      />
    );
  }
}

export default withStyles(aboutUsStyle)(aboutUsDirectors);

aboutUsDirectors.propTypes = {
  classes: PropTypes.object
};
