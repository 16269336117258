import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import PropTypes from "prop-types";
const keyManagement = ({ classes }) => (
  <StaticQuery
    query={graphql`
    query KeyManagementContent{
      allStrapiContacts(
        filter:{
          Unit:{eq: "KeyManagementPersons"}
        },
        sort:{
          fields:sequence,
          order:ASC
        }
      ) {
        edges{
          node{
              id,
              name,
              department,
              designation,
              summary
          }
      }
        }
      }
    `}
    render={data => (
      <GridContainer className={classes.displayFlex}>
        <GridItem md={10} sm={10} className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}>
          <h2 className={classes.title}>Key Management Persons</h2>
        </GridItem>
        {data.allStrapiContacts.edges.map((document, key) => {
          return (
            <GridItem key={key} xs={12} sm={6} md={4}>
              <VerticalCard
                title={document.node.name}
                subtitle={document.node.designation}
                description={document.node.summary}
                alignItems="center"
                noShadow
                onHoverEffect
                style={{
                  marginTop: `10px`
                }}
                height="0px"
              />
            </GridItem>
          );
        })}
      </GridContainer>
    )}
  />
);
keyManagement.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(aboutUsStyle)(keyManagement);

