import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import classNames from "classnames";
// @material-ui/core components
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
const aboutUsOther = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query OtherAboutUsContent {
        allStrapiLinks(filter: { category: { eq: "others" } }) {
          edges {
            node {
              id
              title
              url
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{ paddingTop: `0px` }}>
        <GridItem
          md={10}
          sm={10}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}>
          <h2 className={classes.title}>Others</h2>
        </GridItem>
        {data.allStrapiLinks.edges.map((document, idx) => {
          var name = document.node.title;
          return (
            <GridItem key={idx} xs={12} sm={6} md={3}>
              <Card className={classes.card} style={{ minHeight: 125, textAlign: `center` }}>
                <CardBody>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={document.node.url}>
                    <span>
                      <PDFIcon /> <br /> <span> {name}</span>
                    </span>
                  </a>
                </CardBody>
              </Card>

              {/* description={
                  
                }
                alignItems="center"
                noShadow
                onHoverEffect
              /> */}
            </GridItem>
          );
        })}
      </GridContainer>
    )}
  />
);
aboutUsOther.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(aboutUsStyle)(aboutUsOther);
