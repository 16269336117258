import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
const searchRegExp = /<networkHospitalCount \/>/g
const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

const aboutUsChoose = ({ classes }) => (
  <StaticQuery
    query={graphql`
    query ChooseUsContent{
      allStrapiEntities(
        filter:{
        category:{in: ["why_choose_us","why_choose_us_highlights"]}
      },
      sort:{
        fields:sequence,
        order: ASC
      }
      ){
        edges{
          node{
              id,
              title,
              summary,
              description,
              category,
              displayType,
              icon{
                publicURL
              }
          }
        }
      }
      }
    `}
    render={data => (
      <GridContainer item md={10} className={classNames(classes.mrAuto, classes.mlAuto)} key={`about-us-choose`}>
        {data.allStrapiEntities.edges.map((document) => {
          if (document.node.displayType === `text`) {
            var description = document.node.description.replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT).replace(/\n/g, `<br />`);
            return (
              <GridItem md={12} sm={12} className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)} key={document.node.id}>
                <h2 className={classes.title}>{document.node.title}</h2>
                <Markdown source = {description} />
              </GridItem>
            );
          }
          else if (document.node.displayType === `list`) {
            var desc = document.node.description.replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT);
            var content;
            if(document.node.icon && document.node.title){
              content=(
                <GridItem md={12} sm={12} lg={12} className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}>
                  <h2 className={classes.title}>{document.node.title}</h2>
                </GridItem>
              );
            }else if(document.node.icon){
              content=(
                <GridItem md={4} sm={4} lg={4} className={classes.textCenter}>
                  <Card blog className={`${classes.card} ${classes.heightfix}`} style={{ height: 200 }}
                  >
                    <CardBody plain style={{ padding: `20px 20px` }}>
                      <img src={document.node.icon.publicURL} style={{ height: 40, width: 40 }}></img>
                      <p className={classes.cardDescription} style={{ textAlign: `center`, marginTop: 20 }} ><span>{desc}</span></p>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            }
            return content;
          }
        })}
      </GridContainer>
    )}
  />
);
aboutUsChoose.propTypes = {
  classes: PropTypes.object,

};
export default withStyles(aboutUsStyle)(aboutUsChoose);
