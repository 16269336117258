import React from "react";
import { StaticQuery, graphql } from 'gatsby';
import GridItem from "components/Grid/GridItem";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
const aboutUsOverview = ({ classes }) => (
  <StaticQuery
    query={graphql`
    query AboutUsContent{
      allStrapiEntities(
        filter:{
        category:{eq: "about_us"}
      },
      sort:{
        fields:sequence,
        order: ASC
      }
      ){
        edges{
          node{
                id,
              title,
              summary,
              description,
              category,
              displayType
          }
        }
      }
    }
  `}
    render={data => (
      <GridContainer item md={10} className={classNames(classes.mrAuto, classes.mlAuto)}>
        {/* <Helmet key="helmetTags">
        <title>About Us | StarHealth.in</title>
      </Helmet> */}
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link rel="canonical" href="https://www.starhealth.in/aboutUs" />
          <meta name="title" content="About Us | StarHealth.in" />
          <meta name="twitter:titles" content="About Us | StarHealth.in" />
          <meta
            name="description"
            content="Star Health and Allied Insurance Co Ltd is an Indian health insurance company offering Health Insurance, Personal accident insurance and Overseas travel insurance."
          />
          <meta property="og:title" content="About Us | StarHealth.in" />
          <meta property="og:description" content="Star Health and Allied Insurance Co Ltd is an Indian health insurance company offering Health Insurance, Personal accident insurance and Overseas travel insurance." />
          <meta property="og:url" content="https://www.starhealth.in/aboutUs"/>
          <meta name="About Us | StarHealth.in" />
          <meta name="twitter:description" content="Star Health and Allied Insurance Co Ltd is an Indian health insurance company offering Health Insurance, Personal accident insurance and Overseas travel insurance." />
          <meta property="twitter:url" content="https://www.starhealth.in/aboutUs" />
        </Helmet>
      )}
        {data.allStrapiEntities.edges.map(document => {
          var description = document.node.description.split(`\n`);
          return (
            <GridItem md={12} sm={12} className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)} key={document.node.id}>
              <h1 className={classes.h1title}>{document.node.title}</h1>
              <h5 className={classes.description} style={{textAlign: `left`}}><div>{
                description.map((item, key) => {
                  return (<><div key={key}>{item}</div><br /></>);
                })
              }</div>
              </h5>
            </GridItem>
          );
        })}
      </GridContainer>
    )}
  />
);
aboutUsOverview.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(aboutUsStyle)(aboutUsOverview);
