import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import AboutUsOverview from "./aboutUsOverview.jsx";
import WhyChoose from "./aboutUsChoose.jsx";
import Directors from "./aboutUsDirectors.jsx";
// import TopManagement from "./aboutUsTopMgmt.jsx";
import KeyManagement from "./aboutUsKeyMgmt.jsx";
import CitizenCharter from "./aboutUsCitizen.jsx";
import AboutUsOther from "./aboutUsOther.jsx";
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";

const aboutUs_meta_tags = [
  {
    title: `About Us | StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs`,
    description: `Star Health is India's first Standalone Health Insurance Company which provides affordable Health Coverage Plans for Individuals, Family and Senior Citizens`,
    keywords: `Star Health And Allied Insurance Company Limited, Star Health Care, Star Health Family Insurance, Star Health Insurance, Star Health Insurance Company, Star Health Insurance India, Star Health Insurance Plans, Star Health Insurance Policies, Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy`,
  },
  {
    title: `Why Choose StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs/why-choose-us`,
    description: `With a nation-wide customer base to boast off, we have raised the benchmark of the health insurance sector. Every successful story has been a stepping stone that has made us one of India’s trusted specialist in health insurance`,
  },
  {
    title: `Board of Directors | StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs/board-of-directors`,
    description: `Visit Our Board of Directors Online, People who have more than 40 years of experience in Insurance Industry.`,
  },
  {
    title: `Our Top Management Team | StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs/top-management-team`,
    description: `Explore Our Top Management Team Online, an energetic team who has a very good experience in Health Insurance Industry`,
  },
  {
    title: `Our Key Management Persons | StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs/key-management-persons`,
    description: `Explore Our Key Management Persons Online, an energetic team who has a very good experience in Health Insurance Industry`,
  },
  {
    title: `Proposes to Offer to the Citizens | StarHealth.in`,
    canonicalUrl: `https://www.starhealth.in/aboutUs/content/citizens-charter`,
    description: `StarHealth Insurance Portfolio`,
  },
];

class AboutUs extends React.Component {
  state = { currentTab: 0 };

  changeMetaTag = (newTab) => {
    this.setState({
      currentTab: newTab,
    });
  };

  componentDidMount() {
    this.setState({ currentTab: 0 });
  }
  render() {
    const { classes, data, location } = this.props;
    const { currentTab } = this.state;
    let title, summary;
    var image1 =
      data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
    var image2 =
      data.allStrapiImages.edges[1].node.content.childImageSharp.fluid;
    var navPillArr = data.allStrapiSections.edges.map((document) => {
      title =
        document.node.images && document.node.images.length > 0
          ? document.node.title
          : ``;
      title =
        document.node.images && document.node.images.length > 0
          ? document.node.summary
          : ``;
      var obj = {};
      let content;
      switch (document.node.strapiId) {
        case 12:
          content = <AboutUsOverview />;
          break;
        case 13:
          content = <WhyChoose />;
          break;
        case 14:
          content = <Directors />;
          break;
        // case 15:
        //   content = <TopManagement />;
        //   break;
        case 16:
          content = <KeyManagement />;
          break;
        case 17:
          content = <CitizenCharter />;
          break;
        case 18:
          content = <AboutUsOther />
      }

      // obj.tabCustomIcon =  document.node.image.publicURL;
      obj.tabButton = document.node.title;
      obj.tabImage = document.node.icon && document.node.icon.publicURL;
      obj.tabContent = content;
      return obj;
    });

    return (
      <Layout
        image1={image1}
        image2={image2}
        small={true}
        title={title}
        summary={summary}
      >
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            {aboutUs_meta_tags[currentTab] &&
              aboutUs_meta_tags[currentTab].title && (
                <title>{aboutUs_meta_tags[currentTab].title}</title>
              )}
            {aboutUs_meta_tags[currentTab] &&
              aboutUs_meta_tags[currentTab].title && (
                <Helmet>
                  <meta
                    name="title"
                    content={aboutUs_meta_tags[currentTab].title}
                  />
                  <meta
                    name="twitter:title"
                    content={aboutUs_meta_tags[currentTab].title}
                  />
                  <meta
                    property="og:title"
                    content={aboutUs_meta_tags[currentTab].title}
                  />
                  <meta name={aboutUs_meta_tags[currentTab].title} />
                </Helmet>
              )}
            {aboutUs_meta_tags[currentTab] &&
              aboutUs_meta_tags[currentTab].canonicalUrl && (
                <Helmet>
                  <link
                    rel="canonical"
                    href={aboutUs_meta_tags[currentTab].canonicalUrl}
                  />
                  <meta
                    property="og:url"
                    content={aboutUs_meta_tags[currentTab].canonicalUrl}
                  />
                  <meta property="twitter:url" content={aboutUs_meta_tags[currentTab].canonicalUrl} />
                </Helmet>
              )}
            {aboutUs_meta_tags[currentTab] &&
              aboutUs_meta_tags[currentTab].description && (
                <Helmet>
                  <meta
                    name="description"
                    content={aboutUs_meta_tags[currentTab].description}
                  />
                  <meta
                    property="og:description"
                    content={aboutUs_meta_tags[currentTab].description}
                  />
                  <meta name="twitter:description" content={aboutUs_meta_tags[currentTab].description} />
                </Helmet>
              )}
            {aboutUs_meta_tags[currentTab] &&
              aboutUs_meta_tags[currentTab].keywords && (
                <meta
                  name="keywords"
                  content={aboutUs_meta_tags[currentTab].keywords}
                />
              )}
            {/* <link
              rel="canonical"
              href="https://www.starhealth.in/content/other"
            />
            <meta
              name="title"
              content="Get More Information on StarHealth.in"
            />
            <meta
              name="description"
              content="Download the PDF of the following Corporate Social Responsibility Policy, Terms and Conditions of Appointment of Independent Directors and Whistle Blower Policy"
            /> */}
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: `100px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem>
                  <NavPills
                    changeMetaTag={this.changeMetaTag}
                    color="primary"
                    location={location}
                    tabs={navPillArr.splice(0, 6)}
                    alignCenter
                    variant={true}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

AboutUs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
};
export default withStyles(aboutUsStyle)(AboutUs);

export const aboutUsQuery = graphql`
  query AboutUs {
    allStrapiSections(filter: { category: { eq: "about_us" } }) {
      edges {
        node {
          strapiId
          id
          title
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "about_us" } } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
